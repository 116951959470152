/* eslint-disable no-unused-vars */
import React from 'react';
import cx from 'classnames';
import { getCmsBlocks } from '@core_modules/theme/services/graphql';
import CmsRenderer from '@core_modules/cms/components/cms-renderer';

const GlobalPromoMessage = (props) => {
    const {
        // prettier-ignore
        storeConfig,
    } = props;

    const isGlobalPromoEnabled = storeConfig?.header_animation_enable === '1';
    const block_identifier = storeConfig?.header_animation_cms_block;

    const { data, loading } = getCmsBlocks({
        identifiers: block_identifier,
    });

    if (loading || !isGlobalPromoEnabled) {
        return (
            <></>
        );
    }

    if (!loading && data && data.cmsBlocks && data.cmsBlocks.items.length > 0 && data.cmsBlocks.items[0].content && isGlobalPromoEnabled) {
        return (
            <>
                <div
                    id="global-promo-message"
                    className={cx(
                        'global-promo-message',
                        'h-[38px]',
                        'text-center',
                        'font-normal',
                        'tablet:text-base',
                        'mobile:max-tablet:text-sm',
                        'bg-primary-700',
                        'text-neutral-white',
                    )}
                >
                    <CmsRenderer content={data?.cmsBlocks?.items[0]?.content} storeConfig={storeConfig} />
                </div>
            </>
        );
    }

    return null;
};

export default GlobalPromoMessage;
